import { Box, SxProps } from '@mui/material'
import React from 'react'

export const InfoChip = ({
  text,
  sx,
  onClick,
}: {
  text: string
  sx?: SxProps
  onClick?: VoidFunction
}) => {
  return (
    <Box
      sx={sx}
      onClick={onClick}
      m='4px 4px 4px 0'
      fontSize={14}
      p='3px 7px'
      lineHeight='22px'
      textAlign='start'
      bgcolor='#F7F7FF'
      borderRadius='28px'
    >
      {text}
    </Box>
  )
}
