import { Box, Stack, Typography } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useState } from 'react'
import TelegramIcon from '@mui/icons-material/Telegram'
import { SubheaderIcon } from '../components/SubheaderIcon'
import { InfoChip } from '../components/InfoChip'

const transformDate = (str: string) => {
  const [date, time] = str.split('T')
  const [YYYY, MM, DD] = date.split('-')
  const [hour, min] = time.split(':')

  return `${DD}.${MM}.${YYYY} - ${hour}:${min}`
}

export const TgCommunitiesInfo = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const {
    subscribedChannels,
    notSubscribedChannels,
    subscribedChannelsFromBot,
  } = profileStore

  const [expanded, setExpanded] = useState<string | false>(false)
  const [internalExpanded, setInternalExpanded] = useState<string | false>(
    false
  )

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
      setInternalExpanded(false)
    }

  const handleInternalChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setInternalExpanded(newExpanded ? panel : false)
    }

  const handleLinkClick = useCallback(
    (channel: string) =>
      window.open(`https://t.me/${channel}`, '_blank', 'noopener,noreferrer'),
    []
  )

  return subscribedChannels || notSubscribedChannels ? (
    <Stack sx={{ mt: 2, maxWidth: '900px', width: '100%', px: '16px' }}>
      <Stack direction='row' mb={1}>
        <Box
          sx={{
            mr: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SubheaderIcon
            sx={{ height: 20, width: 20 }}
            name='SmsOutlinedIcon'
          />
        </Box>

        <Typography variant='h6' color='common.black' textAlign='start'>
          Сообщения в Telegram
        </Typography>
      </Stack>

      {subscribedChannels || subscribedChannelsFromBot ? (
        <Stack>
          <Accordion
            sx={{ width: '100%', mb: 0.5 }}
            expanded={expanded === 'tgSubscribed'}
            onChange={handleChange('tgSubscribed')}
          >
            <AccordionSummary>
              <Stack direction='row' spacing={1}>
                <TelegramIcon sx={{ color: 'primary.main' }} />

                <Typography variant='body1' color='common.black'>
                  Подписки Tg
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {subscribedChannelsFromBot ? (
                <Accordion
                  sx={{ width: '100%', mb: 0.5 }}
                  expanded={internalExpanded === 'subscribedChannelsFromBot'}
                  onChange={handleInternalChange('subscribedChannelsFromBot')}
                >
                  <AccordionSummary>
                    <Stack direction='row' spacing={1}>
                      <TelegramIcon sx={{ color: 'primary.main' }} />

                      <Typography variant='body1' color='common.black'>
                        Подписки Tg НОВЫЕ
                      </Typography>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Stack flexDirection='row' flexWrap='wrap'>
                      {subscribedChannelsFromBot?.map(
                        ({ channel_username, participants, title }, idx) => (
                          <InfoChip
                            onClick={() => handleLinkClick(channel_username)}
                            sx={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            key={`${channel_username}_${idx}`}
                            text={`${title || channel_username}${
                              participants
                                ? ` (подписчиков в канале: ${participants})`
                                : ''
                            }`}
                          />
                        )
                      )}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {subscribedChannels?.map(
                ({ posts, channel_id, participants, posts_count, title }) => (
                  <Accordion
                    key={channel_id}
                    sx={{ width: '100%' }}
                    expanded={internalExpanded === String(channel_id)}
                    onChange={handleInternalChange(String(channel_id))}
                  >
                    <AccordionSummary>
                      <Stack direction='row' spacing={0.5}>
                        <Typography color='common.black' variant='body1'>
                          {title}
                        </Typography>

                        <Typography
                          color='common.black'
                          fontWeight={700}
                          variant='body1'
                        >
                          {`${posts_count} сбщ.`}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack>
                        <Typography
                          color='common.black'
                          variant='body1'
                          fontWeight={700}
                        >
                          Подписчиков в канале: {participants}
                        </Typography>
                      </Stack>

                      <Stack spacing={1} mt={1}>
                        {posts?.map(({ date, message }, idx) => (
                          <Stack
                            key={`${date}_${idx}`}
                            direction='row'
                            justifyContent='space-between'
                            sx={{
                              borderBottom:
                                idx !== posts.length - 1
                                  ? '1px solid lightgray'
                                  : 'unset',
                              pb: 1,
                            }}
                          >
                            <Typography
                              variant='body1'
                              color='common.black'
                              width='40%'
                              textAlign='start'
                            >
                              {date ? `${transformDate(date)}: ` : '---'}
                            </Typography>

                            <Typography
                              variant='body1'
                              color='text.disabled'
                              width='60%'
                              textAlign='end'
                            >
                              {message ? message : '---'}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </AccordionDetails>
          </Accordion>
        </Stack>
      ) : null}

      {notSubscribedChannels ? (
        <Stack mb={0.5}>
          <Accordion
            sx={{ width: '100%' }}
            expanded={expanded === 'tgUnsubscribed'}
            onChange={handleChange('tgUnsubscribed')}
          >
            <AccordionSummary>
              <Stack direction='row' spacing={1}>
                <TelegramIcon sx={{ color: 'primary.main' }} />

                <Typography color='common.black' variant='body1'>
                  Чаты Tg
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {notSubscribedChannels.map(
                ({ posts, channel_id, participants, posts_count, title }) => (
                  <Accordion
                    key={channel_id}
                    sx={{ width: '100%' }}
                    expanded={internalExpanded === String(channel_id)}
                    onChange={handleInternalChange(String(channel_id))}
                  >
                    <AccordionSummary>
                      <Stack direction='row' spacing={0.5}>
                        <Typography variant='body1' color='common.black'>
                          {title}
                        </Typography>

                        <Typography
                          variant='body1'
                          color='common.black'
                          fontWeight={700}
                        >
                          {`${posts_count} сбщ.`}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack>
                        <Typography
                          variant='body1'
                          color='common.black'
                          fontWeight={700}
                        >
                          Подписчиков в канале: {participants}
                        </Typography>
                      </Stack>

                      <Stack spacing={1} mt={1}>
                        {posts.map(({ date, message }, idx) => (
                          <Stack
                            key={`${date}_${idx}`}
                            direction='row'
                            justifyContent='space-between'
                            sx={{
                              borderBottom:
                                idx !== posts.length - 1
                                  ? '1px solid lightgray'
                                  : 'unset',
                              pb: 1,
                            }}
                          >
                            <Typography
                              variant='body1'
                              color='common.black'
                              width='40%'
                              textAlign='start'
                            >
                              {date ? `${transformDate(date)}: ` : '---'}
                            </Typography>

                            <Typography
                              variant='body1'
                              width='60%'
                              textAlign='end'
                              color='text.disabled'
                            >
                              {message ? message : '---'}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </AccordionDetails>
          </Accordion>
        </Stack>
      ) : null}
    </Stack>
  ) : null
})
