import { Drawer, Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ForceGraph2D from 'react-force-graph-2d'
import * as S from './styled'
import { useBoolean } from 'hooks/useBoolean'
import CustomList from './custom-list'
import CollapsibleTable from './collapsible-table'
import { Loading } from 'components/Loading'
import * as d3 from 'd3-force'
import CityChart from './cities-chart'
import AgeChart from './age-chart'
import BubbleChart from './bubble-chart'

const calculateNodeSize = (
  rank: number | undefined,
  min: number,
  max: number
): number => {
  if (rank === undefined) {
    return 5 // Размер по умолчанию
  }

  // Нормализуем ранги
  const range = max - min
  if (range === 0) {
    return 10 // Если все значения одинаковые
  }

  const normalizedRank = (rank - min) / range // Нормализуем в диапазоне [0, 1]

  // Определяем категорию
  if (normalizedRank <= 1 / 3) {
    return 15
  } else if (normalizedRank <= 2 / 3) {
    return 10
  }

  return 5
}

const colors = [
  '#D32F2F', // Темно-красный
  '#1976D2', // Темно-синий
  '#388E3C', // Темно-зеленый
  '#F57C00', // Темно-оранжевый
  '#7B1FA2', // Темно-фиолетовый
  '#303F9F', // Темно-голубой
  '#00fff2', // бирюзовый
  '#5D4037', // Темно-коричневый
  '#0288D1', // Темно-лазурный
  '#C2185B', // Темно-розовый
]

const syncLoadAllImages = (imageQueue: any, callback: any) => {
  const numAll = imageQueue.length
  let numProcessed = 0
  const allImages: any = {}

  if (numAll === 0) {
    callback(allImages)
    return
  }

  imageQueue.forEach((img: any) => {
    const image = new Image()
    const id = img.id

    image.addEventListener('load', () => {
      numProcessed++
      allImages[id] = image
      if (numAll === numProcessed) {
        if (callback) {
          callback(allImages)
          return
        }
      }
    })
    image.addEventListener('error', () => {
      numProcessed++
    })
    image.src = img.image
  })
}

export const SocialGraph = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { vkUserInfoGraph } = profileStore

  if (!vkUserInfoGraph) {
    return null
  }

  const graphData = useMemo(() => {
    const links = vkUserInfoGraph?.links.map((link) => ({ ...link }))

    // Найдем минимальный и максимальный rank
    const ranks = Object.values(vkUserInfoGraph.users)
      .map((node) => node.popularity_rank)
      .filter((rank): rank is number => rank !== undefined)

    const minRank = Math.min(...ranks)
    const maxRank = Math.max(...ranks)

    const nodes = Object.entries(vkUserInfoGraph.users).map(([id, value]) => ({
      id: Number(id),
      nodeSize: value?.links_count
        ? calculateNodeSize(value.popularity_rank, minRank, maxRank)
        : 5,
    }))

    return {
      links,
      nodes,
    }
  }, [vkUserInfoGraph?.links, vkUserInfoGraph.users])

  if (!graphData?.nodes?.length) {
    return <Typography>Данные неверны</Typography>
  }

  const open = useBoolean()

  const [selectedData, setSelectedData] = useState<Record<string, any>>()

  const ticks = useBoolean()

  const [highlightNodes, _] = useState(new Set())
  const [clickedNode, __] = useState(null)

  const [photos, setPhotos] = useState([])
  const [images, setImages] = useState({})
  const [isParsing, setIsParsing] = useState(true)

  const [filteredData, setFilteredData] = useState<any>({
    nodes: [],
    links: [],
  })

  useEffect(() => {
    if (graphData) {
      const images = graphData.nodes
        .filter(
          ({ id }) =>
            vkUserInfoGraph.users[id]?.photo_max_res ||
            vkUserInfoGraph.users[id]?.photo_200_orig
        )
        .map(({ id }) => ({
          id,
          image:
            vkUserInfoGraph.users[id]?.photo_max_res ||
            vkUserInfoGraph.users[id]?.photo_200_orig,
        }))

      if (images.length) {
        setImages(images)
      } else {
        setIsParsing(false)
      }

      setFilteredData(graphData)
    }
  }, [graphData, vkUserInfoGraph.users])

  useEffect(() => {
    if (Object.keys(images).length && !photos.length && isParsing) {
      syncLoadAllImages(images, (loadedImages: any) => {
        setPhotos(loadedImages)
        setIsParsing(false)
      })
    }
  }, [photos.length, isParsing, images])

  const graphRef = useRef<any>(null)

  useEffect(() => {
    const fg = graphRef.current

    if (fg && filteredData && !isParsing) {
      // Добавим кастомную силу
      fg.d3Force('centerWithoutLinks', d3.forceCenter())
      fg.d3Force('collision', d3.forceCollide(15)) // Задаем радиус столкновений

      // Группируем ноды без связей
      const nodesWithoutLinks = graphData.nodes.filter(
        (node) =>
          !graphData.links.some(
            (link) => link.source === node.id || link.target === node.id
          )
      )

      // Добавляем силу для всех нод
      fg.d3Force(
        'x',
        d3.forceX((node: any) => (nodesWithoutLinks.includes(node) ? -200 : 0))
      )
      fg.d3Force(
        'y',
        d3.forceY((node: any) => (nodesWithoutLinks.includes(node) ? 0 : 0))
      ) // Опционально для вертикального выравнивания

      // fg.d3Force('link').distance((link: any) => {
      //   // Получаем размеры начального и конечного узлов
      //   const sourceNodeSize = link.source.nodeSize;
      //   const targetNodeSize = link.target.nodeSize;

      //   // Рассчитываем длину связи на основе среднего значения nodeSize
      //   return (sourceNodeSize + targetNodeSize) * 3; // Настройте коэффициент для нужной визуализации
      // });

      // Добавляем столкновения (чтобы ноды не накладывались друг на друга)
      fg.d3Force('collision', d3.forceCollide(15))

      // Перезапускаем симуляцию
      fg.d3ReheatSimulation()

      const id = setTimeout(() => {
        if (!ticks.value) {
          ticks.onTrue()
        }
      }, 3000)

      return () => {
        clearTimeout(id)
      }
    }
  }, [graphData.nodes, graphData.links, isParsing, filteredData, ticks])

  const [graphHeight, ___] = useState(
    document.body.getBoundingClientRect().height * 0.5
  )

  const tableData = useMemo(
    () =>
      vkUserInfoGraph.clusters
        .map((cluster) =>
          Object.fromEntries(
            Object.entries(cluster)
              .map(([key, value]: any) => {
                if (value?.count && value?.percent) {
                  return [key, `${value.count} (${value.percent}%)`]
                }

                if (key === 'tags') {
                  return [
                    key,
                    Object.entries(value)
                      .map((entry) => `${entry[0]}: ${entry[1]}`)
                      .join('; '),
                  ]
                }

                return [key, value]
              })
              .filter(([, v]) => typeof v !== 'object')
          )
        )
        .sort((a: any, b: any) => {
          if (a.cluster_for_singles && !b.cluster_for_singles) {
            return 1
          }
          if (!a.cluster_for_singles && b.cluster_for_singles) {
            return -1
          }

          return b.cluster_size - a.cluster_size
        }),
    [vkUserInfoGraph.clusters]
  )

  const [highlightClusterId, setHighlightClusterId] = useState<number | null>(
    null
  )

  const handleRowClick = useCallback((clusterId: number | null) => {
    setHighlightClusterId(clusterId)
  }, [])

  const handleNodeClick = useCallback(
    (props: Record<string, any>) => {
      if (!open.value) {
        open.onTrue()

        setSelectedData(vkUserInfoGraph.users[props.id])
      }

      const cluster = vkUserInfoGraph?.clusters.find((cluster) =>
        cluster?.members.includes(props?.id)
      )
      if (cluster) {
        setHighlightClusterId(cluster?.id)
      }
    },
    [open, vkUserInfoGraph?.clusters, vkUserInfoGraph?.users]
  )

  if (isParsing) {
    return <Loading />
  }

  return (
    <S.Wrapper>
      <ForceGraph2D
        ref={graphRef}
        onBackgroundClick={() => setHighlightClusterId(null)}
        graphData={filteredData}
        nodeRelSize={1}
        height={graphHeight}
        width={document.body.getBoundingClientRect().width}
        nodeCanvasObject={(node: any, ctx) => {
          const imgSrc = photos?.[node.id]
          const nodeData = vkUserInfoGraph?.users?.[node.id]

          const rank = node.nodeSize

          ctx.beginPath()

          if (highlightNodes.has(node) && node === clickedNode) {
            const D = rank
            const R = D / 2

            if (imgSrc) {
              ctx.rect(node.x - R, node.y - R, D, D)
            } else {
              ctx.arc(node.x, node.y, R, 0, 2 * Math.PI, false)
            }

            ctx.strokeStyle = '#5578EB'
            ctx.stroke()
          }

          const D = rank
          const R = D / 2

          if (!imgSrc) {
            ctx.arc(node.x, node.y, R, 0, 2 * Math.PI, false)

            ctx.fillStyle = '#69B7FF'
            ctx.fill()
          }

          if (imgSrc) {
            // Сохранить текущий контекст
            ctx.save()

            // Создать круг для обрезки изображения
            ctx.beginPath()
            ctx.arc(node.x, node.y, R, 0, 2 * Math.PI)
            ctx.closePath()

            // Ограничить область рисования кругом
            ctx.clip()

            // Нарисовать изображение
            ctx.drawImage(imgSrc, node.x - R, node.y - R, D, D)

            ctx.restore() // Восстановить контекст перед рисованием обводки

            // Нарисовать круговую обводку
            ctx.beginPath()
            ctx.arc(node.x, node.y, R + 0.025 * rank, 0, 2 * Math.PI)
            ctx.closePath()
            ctx.strokeStyle =
              nodeData?.links_count === 0
                ? 'darkgrey'
                : colors[nodeData.cluster_id % colors.length]
            ctx.lineWidth = 0.05 * rank
            ctx.stroke()
          } else {
            const img = new Image()

            ctx.drawImage(
              img,
              node.x - R * 1.5,
              node.y - R * 1.5,
              D * 1.5,
              D * 1.5
            )
          }
        }}
        linkColor={(link) => {
          return highlightClusterId &&
            vkUserInfoGraph.clusters
              .find((cluster) => cluster.id === highlightClusterId)
              ?.members.includes(link.source.id)
            ? colors[highlightClusterId % colors.length]
            : 'rgba(0, 0, 0, 0.1)'
        }}
        linkCurvature={0.15}
        linkDirectionalArrowLength={({ ordered }) => (ordered ? 4 : 0)}
        linkDirectionalArrowRelPos={1}
        linkWidth={(link) => (link.ordered ? 2 : 1)}
        onNodeClick={handleNodeClick}
        enableNodeDrag
        nodeVal={(node) => Math.pow(node.nodeSize, 2) / 3}
        onNodeDrag={(node: any) => {
          node.fx = node.x
          node.fy = node.y
        }}
        cooldownTicks={ticks.value ? 0 : undefined}
        autoPauseRedraw={false}
        onNodeDragEnd={(node) => {
          node.fx = node.x
          node.fy = node.y
        }}
        backgroundColor='#f1f0fa'
      />

      <Stack>
        <Typography variant='h6' my={2}>
          Данные по кластерам
        </Typography>

        <CollapsibleTable
          highlighedClusterId={highlightClusterId}
          data={tableData}
          onRowClick={handleRowClick}
        />
      </Stack>

      <Stack>
        <Typography my={2} variant='h6'>
          Города пользователей
        </Typography>

        <Typography mb={2} variant='caption' color='text.secondary'>
          Для просмотра данных по конкретной группе - кликните на нее в легенде
        </Typography>

        <CityChart data={vkUserInfoGraph.clusters} />
      </Stack>

      <Stack>
        <Typography my={2} variant='h6'>
          Возраста пользователей
        </Typography>

        <Typography mb={2} variant='caption' color='text.secondary'>
          Для просмотра данных по конкретной группе - кликните на нее в легенде
        </Typography>

        <AgeChart users={vkUserInfoGraph.users} />
      </Stack>

      <Stack>
        <Typography my={2} variant='h6'>
          Лидеры в своей группе
        </Typography>

        <BubbleChart users={vkUserInfoGraph.users} />
      </Stack>

      <Drawer
        anchor='right'
        open={open.value}
        onClose={() => open.onFalse()}
        sx={{ zIndex: 10 }}
      >
        <S.Sidebar>
          {selectedData ? <CustomList data={selectedData} /> : null}
        </S.Sidebar>
      </Drawer>
    </S.Wrapper>
  )
})
